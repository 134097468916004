<template>
  <div class="search-box">
    <div class="time-screen-box">
      <a-button type="primary" class="search-screen-button" @click="handleButton('timeScreen')">
        时间筛选
        <CaretUpOutlined v-show="popupShow.timeScreen" />
        <CaretDownOutlined v-show="!popupShow.timeScreen" />
      </a-button>
      <div class="time-screen" v-show="popupShow.timeScreen">
        <div class="public-less-decoration"></div>
        <div class="time-screen-content">
          <div class="time-screen-top">
            <div class="picker-box">
              <a-date-picker
                dropdownClassName="live-broadcast-picker"
                v-model:value="startValue"
                :disabled-date="disabledStartDate"
                format="YYYY-MM-DD"
              />
              <div class="horizontal-line"></div>
              <a-date-picker
                dropdownClassName="live-broadcast-picker"
                v-model:value="endValue"
                :disabled-date="disabledEndDate"
                format="YYYY-MM-DD"
                :open="endOpen"
                @openChange="handleEndOpenChange"
              />
            </div>
          </div>
          <div class="search-public-bottom">
            <a-button type="primary" ghost @click="reset('time')">重置</a-button>
            <a-button type="primary" @click="screenSearch">搜索</a-button>
          </div>
        </div>
      </div>
    </div>
    <div class="tag-filtering-box">
      <a-button type="primary" class="search-screen-button" @click="handleButton('tagFiltering')">
        标签筛选
        <CaretUpOutlined v-show="popupShow.tagFiltering" />
        <CaretDownOutlined v-show="!popupShow.tagFiltering" />
      </a-button>
      <div class="tag-filtering" v-show="popupShow.tagFiltering">
        <div class="public-less-decoration"></div>
        <div class="tag-filtering-content">
          <Label />
          <div class="search-public-bottom">
            <a-button type="primary" ghost @click="reset('lablelist')">重置</a-button>
            <a-button type="primary" @click="screenSearch">搜索</a-button>
          </div>
        </div>
      </div>
    </div>
    <div class="publisher-filtering-box">
      <a-button type="primary" class="search-screen-button" @click="handleButton('publisherFiltering')">
        发布者筛选
        <CaretUpOutlined v-show="popupShow.publisherFiltering" />
        <CaretDownOutlined v-show="!popupShow.publisherFiltering" />
      </a-button>
      <div class="publisher-filtering" v-show="popupShow.publisherFiltering">
        <div class="public-less-decoration"></div>
        <div class="publisher-filtering-content">
          <a-tree checkable v-model:checkedKeys="checkedKeys" :tree-data="treeData" @check="onTreeClick" />
          <div class="search-public-bottom">
            <a-button type="primary" ghost @click="reset('publisherFiltering')">重置</a-button>
            <a-button type="primary" @click="screenSearch">确认</a-button>
          </div>
        </div>
      </div>
    </div>
    <a-input-search
      v-model:value="searchContent"
      :placeholder="placeholder"
      enter-button="搜索"
      size="large"
      class="search-input"
      @search="screenSearch"
    />
    <div class="search-screen"></div>
  </div>
</template>

<script>
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons-vue';
import { useRouter } from 'vue-router';
import { reactive, ref, inject, onMounted, provide } from 'vue';
import Label from './Label.vue';
import { FormatTime } from '@/utils/tool';
import api from '@/services';
export default {
  components: {
    CaretDownOutlined,
    CaretUpOutlined,
    Label
  },
  props: {
    placeholder: {
      type: String
    }
  },

  setup(props, context) {
    // 弹框的显示和隐藏
    const popupShow = reactive({
      timeScreen: false,
      tagFiltering: false,
      publisherFiltering: false
    });

    const handleButton = params => {
      Object.keys(popupShow).forEach(key => {
        if (params != key) popupShow[key] = false;
      });
      popupShow[params] = !popupShow[params];
    };

    // 日期
    const startValue = ref();
    const endValue = ref();
    const endOpen = ref(false);

    const disabledStartDate = startValue => {
      if (!startValue || !endValue.value) {
        return false;
      }
      return startValue.valueOf() > endValue.value.valueOf();
    };
    const disabledEndDate = endValue => {
      if (!endValue || !startValue.value) {
        return false;
      }
      return startValue.value.valueOf() >= endValue.valueOf();
    };
    const handleEndOpenChange = open => {
      endOpen.value = open;
    };

    // 标签筛选
    const labelList = inject('labelList');
    const activeLable = ref([]);
    // 搜索框
    const searchContent = ref('');

    // 查询方法
    const params = inject('params');
    const screenSearch = () => {
      const keys_params = Object.keys(params.value);
      if (labelList.value) {
        labelList.value.forEach(item => {
          if (item.active) activeLable.value.push(item.id);
        });
      }
      params.value[keys_params[0]] = FormatTime(startValue.value);
      params.value[keys_params[1]] = FormatTime(endValue.value);
      params.value[keys_params[2]] = activeLable.value;
      params.value[keys_params[3]] = checkedKeys_v.value;
      params.value[keys_params[4]] = searchContent.value;
      context.emit('getList');
    };

    // 标签筛选重置
    const reset = parms => {
      const obj = {
        time: () => {
          startValue.value = '';
          endValue.value = '';
        },
        lablelist: () => {
          activeLable.value = []
          labelList.value.forEach(value => {
            value.active = false;
          });
        },
        publisherFiltering: () => {
          checkedKeys.value = []
          checkedKeys_v.value = []
        }
      };
      obj[parms]();
      screenSearch();
    };

    // 页面跳转
    const router = useRouter();
    const sendJump = pathName => {
      router.push({ name: pathName });
    };

    // 发布者筛选
    const treeData = ref();
    
    const publisherFiltering = async () => {
      const { data } = await api.publisherFiltering();
      treeData.value = data;
    };
    const checkedKeys_v = ref([])
    const onTreeClick = (checkedKeys, { node }) => {
      checkedKeys_v.value = checkedKeys.map(Number).filter(item => item);
    };

    onMounted(() => {
      publisherFiltering();
    });

    const checkedKeys = ref([])

    return {
      // 发布者筛选
      treeData,
      checkedKeys,
      onTreeClick,

      // 方法
      handleButton,
      screenSearch,
      sendJump,
      popupShow,
      reset,

      // 日期
      startValue,
      endValue,
      endOpen,
      disabledStartDate,
      disabledEndDate,
      handleEndOpenChange,
      // 搜索框内容
      searchContent,
    };
  }
};
</script>

<style lang="less" scoped>
@import '~@less/public.less';
.search-box {
  width: 837px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 40px;
  position: relative;
  top: -25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .time-screen-box,
  .tag-filtering-box,
  .publisher-filtering-box {
    position: relative;
  }
  .time-screen,
  .tag-filtering,
  .publisher-filtering {
    .bullet-frame(@name: public-less);
    width: unset;
    left: unset;
    right: unset;
    transform: unset;
    top: 47px;
    &::before {
      left: 48px;
      transform: unset;
    }
    .search-public-bottom {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      & > button:first-of-type {
        margin-right: 10px;
      }
      button {
        width: 68px;
        height: 24px;
        padding: 0;
        border-radius: 3px;
      }
    }
  }
  .time-screen {
    .time-screen-content {
      padding: 20px;
      .time-screen-top {
        display: flex;
        white-space: nowrap;
        .picker-box {
          display: flex;
          align-items: center;
          .horizontal-line {
            width: 16px;
            height: 1px;
            margin: 0 6px;
            background-color: #e5e5e5;
          }
          span {
            width: 106px;
            min-width: unset !important;
            ::v-deep(input) {
              border-radius: 3px;
              padding: 2px 11px;
              font-size: 12px;
              &:placeholder-shown {
                font-size: 12px;
              }
            }
          }
        }
        .positive-negative {
          color: #999999;
          margin-left: 20px;
          display: Flex;
          align-items: center;
          span {
            cursor: pointer;
          }
          .positive-negative-active {
            color: #ff872c;
          }
          .positive-negative-vertical {
            display: inline-block;
            width: 1px;
            height: 13px;
            background-color: #e5e5e5;
            margin: -1px 5px 0;
          }
        }
      }
    }
  }
  .tag-filtering {
    .tag-filtering-content {
      padding: 20px 10px 20px 20px;
      width: 387px;
      .tag-list {
        padding: 0;
        width: unset;
        overflow-y: auto;
        background-color: #fff;
        border-radius: 0;
        ::v-deep(.tag-list-wrapper) {
          max-height: 92px;
          grid-template-columns: repeat(4, 78px);
          grid-auto-rows: 24px;
          grid-column-gap: 10px;
          grid-row-gap: 10px;
          &::-webkit-scrollbar {
            width: 5px;
          }
        }
      }
      .search-public-bottom {
        margin-right: 20px;
      }
    }
  }
  .publisher-filtering {
    .publisher-filtering-content {
      padding: 24px 20px;
      width: 375px;
      .ant-tree {
        max-height: 250px;
        overflow-y: auto;
        ::v-deep(li) {
          padding: 0;
          margin: 4px 0;
        }
        .scroll();
        &::-webkit-scrollbar {
          width: 5px;
        }
      }
    }
  }
  .all-type {
    .ant-select {
      width: 106px;
      margin-right: 30px;
      ::v-deep(.ant-select-selector) {
        border-radius: 5px;
        height: 30px;
      }
      ::v-deep(.ant-select-selection-placeholder) {
        color: #333333;
      }
    }
  }
  .search-screen-button {
    margin-right: 30px;
    width: 101px;
    border: none;
    height: 30px;
    border-radius: 5px;
    background-color: #eeeeee;
    color: #333333;
    ::v-deep(span) {
      vertical-align: middle;
    }
    .anticon {
      color: #999999;
    }
    &:last-of-type {
      width: 115px;
    }
  }
  .search-input {
    ::v-deep(.ant-input) {
      height: 30px;
      font-size: 14px;
      padding-left: 20px;
      &::placeholder {
        color: #999999;
      }
      border-radius: 5px 0 0 5px;
    }
    ::v-deep(.ant-btn-lg) {
      height: 30px;
      font-size: 14px;
      border-radius: 0 5px 5px 0;
    }
  }

  .launch-live {
    height: 30px;
    border-radius: 5px;
    border: none;
    background-color: #ff872c;
  }
}
</style>
