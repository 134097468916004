<template>
  <div class="tag-list">
    <div class="tag-list-wrapper">
      <span v-for="(item, i) in labelList" :class="{ 'tag-active': item.active }" @click="selectLabel(item)" :key="i">
        <strong>
          {{ item.labelName }}
        </strong>
      </span>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
export default {
  setup() {
  
    const labelList = inject('labelList');
    const selectLabel = params => {
      params.active = !params.active;
    };
    return {
      labelList,
      selectLabel,
    };
  },
};
</script>

<style lang="less" scoped>
@import '~@less/public.less';
.tag-list {
  padding: 18px 12px 18px 22px;
  width: 492px;
  background-color: #f6f6f6;
  border-radius: 10px;
  .tag-list-wrapper {
    overflow-y: auto;
    max-height: 144px;
    display: grid;
    grid-template-columns: repeat(5, 70px);
    grid-auto-rows: 25px;
    grid-column-gap: 15px;
    grid-row-gap: 14px;
    .scroll();
    span {
      border-radius: 13px;
      border: 1px solid #d4d4d4;
      display: flex;
      font-size: 12px;
      cursor: pointer;
      strong {
        margin: auto;
        font-weight: inherit;
        text-align: center;
        display: block;
        .text-overflow(100%);
        width: calc(100% - 10px);
      }
    }
    .tag-active {
      background-color: #007fff;
      color: #fff;
    }
  }
}
</style>
